<template>
  <div class="nav-button-area">
    <router-link class="nav-item" tag="a" to="/users" exact>
     <AppstoreOutlined /> Kullanıcılar Listesi
    </router-link>
    <router-link class="nav-item" tag="a" to="/categories">
      <DeploymentUnitOutlined/>
      Kategori Listesi
    </router-link>
    <router-link class="nav-item" tag="a" to="/courses">
      <DeploymentUnitOutlined/>
      Kurs Listesi
    </router-link>
    <router-link class="nav-item" tag="a" to="/levels">
      <DeploymentUnitOutlined/>
      Level Listesi
    </router-link>

    <router-link class="nav-item" tag="a" to="/files">
      <DeploymentUnitOutlined/>
      Dosya Listesi
    </router-link>


    <a @click.prevent="logout" class="nav-link" href="#"><LogoutOutlined />Çıkış</a>
  </div>

</template>
<script>
  import {AppstoreOutlined , DeploymentUnitOutlined , LogoutOutlined} from '@ant-design/icons-vue'
  export default {


    name:'Navigation',
    components:{
      AppstoreOutlined,
      DeploymentUnitOutlined,

      // ApartmentOutlined,
      // ContainerOutlined,
      // GlobalOutlined,
      LogoutOutlined
    },

    data(){
      return{

      }
    },


    created() {

    },

    methods: {
      logout() {
        this.$store.dispatch("logout")
      },
    },

  }
</script>

<style lang="scss" scoped>
.nav-button-area{
}


.nav-button-area{
    background: rgb(48, 65, 86);

    display: flex;
    flex-direction: column;
    border-right: 1px solid #1890ff;
    height: 100vh;


    &>a{
      line-height: 60px;
      color: rgb(191, 203, 217);
      background: rgb(48, 65, 86);
      padding-left: 20px;
      font-weight: 500;

      &:hover{
        background: #263445;
      }

      &.router-link-active{
        color: rgb(64, 158, 255);
        background: #263445;

      }
      span{
        margin-right: 8px;
      }
    }


  }
</style>
