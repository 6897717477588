import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'


const routes = [

  {
    path: '/',
    name: 'MainPage',
    props: true,
    component: () => import ('../pages/User/UserList')
  },
  {
    path: '/users',
    name: 'UserList',
    props: true,
    component: () => import ('../pages/User/UserList')
  },
  {
    path: '/categories',
    name: 'CategoryList',
    props: true,
    component: () => import ('../pages/Categories/CategoryList.vue')

  },
  {
    path: '/courses',
    name: 'CourseList',
    props: true,
    component: () => import ('../pages/Courses/CourseList')

  },
  {
    path: '/levels',
    name: 'LevelList',
    props: true,
    component: () => import ('../pages/Levels/LevelList.vue')

  },
  {
    path: '/levels/:levelId/files',
    name: 'LevelFileList',
    props: true,
    component: () => import ('../pages/Levels/LevelFileList.vue')

  },
  {
    path: '/files',
    name: 'FileList',
    props: true,
    component: () => import ('../pages/Files/FileList.vue')

  },

  // {
  //   path: "/users/new",
  //   name: "AddUser",
  //   props: true,
  //   component: () =>
  //       import ("../pages/User/AddOrEditUser")
  // },
  {
    path: '/users/:userId/edit',
    name: 'EditUser',
    props: true,
    component: () =>
        import ('../pages/User/AddOrEditUser')
  },
  {
    path: '/auth',
    name: 'auth',
    props: true,

    component: () =>
        import('../pages/Auth')
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
