import {createApp} from 'vue'
import Antd, {notification} from 'ant-design-vue'
import App from './App.vue'
import 'ant-design-vue/dist/antd.css'

import router from './router/router'
import store from './store/store'
import moment from 'moment'

import axios from 'axios'
import VueAxios from 'vue-axios'

const config = require('../config')


const app = createApp(App)
app.config.productionTip = false
app.config.silent = true


app.use(VueAxios, axios)
app.use(store)
app.use(router)
app.use(Antd)
app.use(moment)


axios.defaults.baseURL = config.BACKEND_URL
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
const errorHandler = (error) => {
  if (error.response.status === 401) {
    return store.dispatch('logout')
  }
  notification.error({message:error.response.data.message})
  throw error
}

axios.interceptors.response.use(response => response,
    error => errorHandler(error))
app.provide('axios', app.config.globalProperties.axios)

app.mount('#app')
