<template>
  <div class="panel-wrapper">
    <a-col :span="3" v-if="is_active">
      <Header/>
    </a-col>
    <a-col :span="21">
<!--      <a-spin class="spin-style" tip="Loading..." size="large" />-->
      <router-view>

      </router-view>
    </a-col>

  </div>
</template>
<script>
  import Header from "./router/Navigation.vue"

  export default {
    components: {
      Header,
    },
    data() {
      return {
        active: ''
      }
    },
    created() {
      this.$store.dispatch("initAuth")
    },
    computed: {

      is_active(){
        let lodged = this.$store.state.is_logged

        if(lodged===undefined) {
          return

        }
        return lodged
      },

    },
  }
</script>

<style lang="scss">
  @import "./assets/scss/app";
  .fullSize{
    width: 100%;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }

</style>

