import {createStore} from 'vuex'
import router from '@/router/router'
import axios from 'axios'

export default createStore({

  state: {
    is_logged: false,
    loading: false,
    token: localStorage.getItem('token'),
    selectedCompany: []
  },
  actions: {

    initAuth({commit, dispatch, state}) {
      let token = localStorage.getItem('token')

      if ( ! token) {
        return dispatch('logout')
      }
      state.is_logged = true

      state.token = localStorage.getItem('token')
    },

    login({commit}, authData) {

      commit('setToken', authData, authData.token)

      localStorage.setItem('token', authData.token)


    },
    logout({commit}) {

      commit('clearToken')
      localStorage.removeItem('token')
      localStorage.removeItem('expirationDate')

      return router.replace('/auth')

    }


  },
  mutations: {


    setToken(state, token) {

      state.token = token
      state.is_logged = true

    },

    clearToken(state) {

      state.token = ''
      state.is_logged = false
    }

  },
  getters:{
    getToken(state){
      return state.token
    }
  }
})

